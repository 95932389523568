require('sidr/dist/jquery.sidr');

$(function () {

    $('#sidr-menu').removeClass('d-none');
    var $side_menu = $('#side-menu');
    $side_menu.sidr({
        name: 'sidr-menu',
        bind: 'click',
        displace: true,
        onOpen: function () {
            $('#sidr-menu').toggleClass('shadow-lg');

            $('.backdrop').css('height', document.body.clientHeight + 'px');
            $('.backdrop').show();

        },
        onOpenEnd: function () {

        },
        onClose: function () {
            $('#sidr-menu').toggleClass('shadow-lg');
            $('.backdrop').hide();
        }
    });

    $('.close-side-menu, .backdrop').on('click', function () {
        $.sidr('close', 'sidr-menu');
    });

});
