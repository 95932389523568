window._ = require('lodash');
// window.axios = require('axios');
// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
require('bootstrap');

window.$ = window.jQuery = require('jquery');

require('slick-carousel');

require('./slick-slider');

require('./sidr');


$(function () {

    $('.slider-quem-somos').slick();

    console.log($('.slider-quem-somos'));

    $('.slider-banner').slick();

    console.log($('.slider-banner'));

});

