$('.slider-quem-somos').slick({
    infinite: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 300,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 6000,
  });

  $('.slider-banner').slick({
    infinite: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 300,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 6000,
  });